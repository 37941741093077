<script>
import ProfileForm from '@/components/settings/ProfileForm'
import ConfirmationModal from '@/components/general/ConfirmationModal'
import SurveyModal from '@/components/general/SurveyModal'
import TextArea from '@/components/general/TextArea'
export default {
  name: 'ConfigurationSubmenu',
  components: {
    SurveyModal,
    ConfirmationModal,
    ProfileForm,
    TextArea
  },
  data () {
    return {
      showModalSurvey: false,
      showModalRemoveWorkspace: false,
      confirmationRemovalText: '',
      survey: {
        answer: null,
        reason: null,
        type: 'workspace_remotion'
      },
      answerOptions: [
        { text: this.$t('survey.remove.workspace.reason.options:1'), value: 'The functionality does not meet what I needed' },
        { text: this.$t('survey.remove.workspace.reason.options:2'), value: 'I started using another service' },
        { text: this.$t('survey.remove.workspace.reason.options:3'), value: 'Other reasons' }
      ]
    }
  },
  computed: {
    validatedTextToRemoveAccount () {
      return this.confirmationRemovalText.toLowerCase() === this.$t('global:removal').toLocaleLowerCase()
    },
    userName () {
      return this.$store.getters.getUser.name
    }
  },
  methods: {
    removeWorkspace () {
      this.showModalRemoveWorkspace = false
      this.showModalSurvey = true
    },
    submitWorkspaceRemoval () {
      return this.$store.dispatch('attemptDeleteWorkspace', this.selectedWorkspace.lxID)
    },
    submitSurvey () {
      this.$store.dispatch('attemptSubmitSurvey', this.survey).then(() => {
        this.submitWorkspaceRemoval()
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'success',
          title: this.$t('survey.submit:success')
        })
        this.cancelSurvey()
      }).catch(() => {
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'error',
          title: this.$t('survey.submit:error')
        })
      })
    },
    cancelSurvey () {
      this.showModalSurvey = false
      this.submitWorkspaceRemoval().then(() => {
        this.$store.dispatch('attemptGetUserWorkspaces').then(() => {
          this.$store.commit('setSelectedWorkspace', this.$store.getters.getUserWorkspaces.find(workspace => {
            return workspace.id === this.$store.getters.getIndividualWorkspace.id
          }))
          this.$router.push({ name: this.selectedWorkspace.type + '.home' })
        })
      })
    },
    emitHasChangesToBeSaved (value) {
      this.$emit('hasChangesToBeSaved', value)
    }
  },
  created () {

  }
}
</script>
<template>
  <v-card class="workspace-management--configuration-container" elevation="0">
    <profile-form getSelectedWorkspace @hasChangesToBeSaved="emitHasChangesToBeSaved"/>
    <div class="workspace-management--remove-workspace">
      <div class="delete-workspace--header">
        <h5 class="h7">{{ $t('workspace.management:delete.workspace.header') }}</h5>
        <p class="body--1">{{ $t('workspace.management:delete.workspace.paragraph') }}</p>
      </div>
      <v-btn
        dark
        small
        @click="showModalRemoveWorkspace = true"
        color="#E83651"
      >
        <b>{{ $t('workspace.management:remove.workspace.btn') }}</b>
      </v-btn>
    </div>
    <confirmation-modal
      :show="showModalRemoveWorkspace"
      :title="$t('global:remove.workspace')"
      :description="$t('workspace.removal.labels:modal.confirm.description')"
      :confirmText="$t('global:remove.workspace')"
      confirmStyle="warning"
      :btnConfirmText="false"
      :disableConfirmBtn="!validatedTextToRemoveAccount"
      @confirm="removeWorkspace"
      :cancelText="$t('global:cancel')"
      @cancel="showModalRemoveWorkspace = false"
      :maxWidth="590"
    >
      <div class="spaced-slot">
        <p class="text-left bolder darker" v-html="$t('settings.account.labels:modal.confirm.slot.description')"></p>
        <v-text-field
          outlined
          v-model="confirmationRemovalText"
          :placeholder="$t('global:removal').toUpperCase()"
        />
      </div>
    </confirmation-modal>
    <survey-modal
      :show="showModalSurvey"
      :title="$t('survey.remove.workspace.labels:title')"
      :description="$t('survey.remove.workspace.labels:description')"
      :confirmText="$t('global:send')"
      :cancelText="$t('global:exit')"
      :btnConfirmDisabled="!survey.answer"
      @cancel="cancelSurvey"
      @confirm="submitSurvey"
    >
      <div class="survey-body--wrapper">
        <p class="text-left darker" v-html="$t('survey.remove.workspace.labels:leaving.text', { name: userName.split(' ')[0] })"></p>
        <v-radio-group v-model="survey.answer">
          <v-radio
            v-for="(answer, index) in answerOptions"
            :key="index"
            :label="answer.text"
            :value="answer.value"
          ></v-radio>
        </v-radio-group>
        <text-area
          v-model="survey.reason"
          outlined
          :placeholder="$t('survey.remove.workspace.labels:answer.textarea.placeholder')"
          height="110"
        ></text-area>
      </div>
    </survey-modal>
  </v-card>
</template>
<style lang="scss">
.workspace-management--configuration-container {
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 70px;
  .workspace-management--remove-workspace {
    border-top: 1px solid $neutral-light;
    padding: 50px 0 0;
    margin: 0 20px 0;
    text-align: left;
    .v-btn {
      margin: 0;
    }
  }
}
</style>
