<script>
import Banner from '@/components/general/Banner'
import ConfigurationSubmenu from '@/components/workspace/businessWorkspace/WorkspaceManagement/ConfigurationSubmenu'
export default {
  name: 'HomepageUserManagement',
  components: { ConfigurationSubmenu, Banner }
}
</script>
<template>
  <div class="homepage-user-management--container">
    <banner
      background-color="secondary-lightest"
      background-figure="/assets/images/workspace-banners/management.png"
      background-position="center right"
    >
      <div class="homepage-user-management--banner__wrapper">
        <h5>{{ $t('home.user.management.banner:title') }}</h5>
        <p class="body--1">{{ $t('home.user.management.banner:subtitle') }}</p>
      </div>
    </banner>
    <configuration-submenu/>
  </div>
</template>
<style lang="scss">
.homepage-user-management--container {
  .homepage-user-management--banner__wrapper {
    text-align: left;
    padding: 50px 24px;
    > * {
      margin: 0;
    }
  }
}
</style>
