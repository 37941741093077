<script>
export default {
  name: 'SurveyModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 550
    },
    btnConfirmText: {
      type: Boolean,
      default: false
    },
    btnConfirmDisabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: ''
    },
    cancelText: {
      type: String,
      default: ''
    }
  }
}
</script>
<template>
  <v-dialog
    :value="show"
    :key="show"
    content-class="dialog-confirm--modal"
    persistent
    :max-width="maxWidth"
  >
    <v-card class="survey-modal--container">
      <v-card-title class="titleContent">
        {{ title }}
      </v-card-title>
      <span class="top-right">
        <v-btn icon @click="$emit('cancel')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>
      <p class="text-left darker" v-html="description"></p>
      <div class="section-divider"/>
      <slot></slot>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="$emit('cancel')"
          class="text-secondary mr-8"
        >
          {{ cancelText }}
        </v-btn>
        <v-btn
          :text="btnConfirmText"
          :dark="!btnConfirmDisabled"
          :disabled="btnConfirmDisabled"
          color="#4938FF"
          @click="$emit('confirm')"
        >
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.survey-modal--container {
  .section-divider {
    display: block;
    height: 1px;
    width: 100%;
    margin: 25px 0;
    background: $neutral-light;
  }
  &.v-card {
    .v-card__actions {
      padding: 8px 0;
      .v-btn {
        padding: 0 16px;
      }
    }
  }
}
</style>
