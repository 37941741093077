<script>
export default {
  name: 'Banner',
  props: {
    backgroundColor: {
      type: String
    },
    backgroundFigure: {
      type: String
    },
    backgroundFigureAlt: {
      type: String,
      default: ''
    },
    backgroundPosition: {
      type: String
    }
  },
  methods: {
    getBackgroundColor () {
      if (!['primary-medium', 'secondary-lightest'].includes(this.backgroundColor)) {
        return {
          background: this.backgroundColor
        }
      }
    }
  }
}
</script>
<template>
  <div class="banner--container" :class="[backgroundColor]" :style="[getBackgroundColor()]">
    <div class="banner--body">
      <slot></slot>
    </div>
    <div class="banner--figure" :class="[backgroundPosition]">
      <img :src="backgroundFigure" :alt="backgroundFigureAlt"/>
    </div>
  </div>
</template>
<style lang="scss">
.banner--container {
  width: 100%;
  display: flex;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  &.primary-medium {
    background: $primary-medium;
  }
  &.secondary-lightest {
    background: $secondary-lightest;
  }
  .banner--body {
    width: calc(100% - 255px);
  }
  .banner--figure {
    width: 255px;
    display: flex;
    position: absolute;
    right: 0;
    bottom: -5px;
    &.center {
      align-items: center;
    }
    &.bottom {
      align-items: flex-end;
    }
    &.right {
      justify-content: flex-end;
      margin-right: 24px;
    }
  }
  @media only screen and (max-width: 768px) {
    border-radius: 0;
    .banner--body {
      width: 100%;
      z-index: 5;
    }
    .banner--figure {
      opacity: .34;
      z-index: 1;
    }
  }
}
</style>
